import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sort-table-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './sort-table-header.component.html',
  styleUrl: './sort-table-header.component.scss'
})
export class SortTableHeaderComponent {
  @Input() label: string = '';
  @Input() field: string = '';

  @Input() sortField: string = '';
  @Input() sortOrder: number = 1;
}
